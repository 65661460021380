@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .privacy-par {
    @apply pt-1;
  }

  .privacy-header {
    @apply text-3xl font-black mb-2 mt-6 underline decoration-yellow-500 decoration-4 underline-offset-8;
  }

  .dynamic-card {
    @apply sm:px-16;
  }

  .portfolio-container {
    @apply grid sm:grid-cols-2 gap-8 px-12 sm:px-0;
  }

  .portfolio-image {
    @apply rounded-md duration-200 object-fill hover:scale-105 w-72 sm:w-52 h-32;
  }

  .portfolio-item {
    @apply rounded-lg;
  }

  .portfolio-links {
    @apply flex items-center justify-center;
  }

  .portfolio-btn {
    @apply w-1/2 px-6 py-3 m-4 duration-200 hover:scale-105;
  }

  .dropdown-btn {
    @apply p-4 text-white hover:bg-blue-600 rounded;
  }
}
